import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M2.75,4 C3.16421356,4 3.5,4.33578644 3.5,4.75 L3.5,18.749 L5,18.749 L5,12.5 C5,11.3954305 5.8954305,10.5 7,10.5 L8,10.5 C9.1045695,10.5 10,11.3954305 10,12.5 L10,18.749 L11,18.749 L11,9.5 C11,8.3954305 11.8954305,7.5 13,7.5 L14,7.5 C15.1045695,7.5 16,8.3954305 16,9.5 L16,18.749 L17,18.749 L17,6.5 C17,5.3954305 17.8954305,4.5 19,4.5 L20,4.5 C21.1045695,4.5 22,5.3954305 22,6.5 L22,19.5 L22,19.5 C22,19.9142136 21.6642136,20.25 21.25,20.25 L2.75,20.25 C2.33578644,20.25 2,19.9142136 2,19.5 C2,19.4570289 2.00361383,19.4149018 2.01055416,19.3739061 C2.00355483,19.3344075 2,19.2926189 2,19.25 L2,4.75 C2,4.33578644 2.33578644,4 2.75,4 Z M7.5,12 C6.98716416,12 6.56449284,12.3860402 6.50672773,12.8833789 L6.5,13 L6.5,18.749 L8.5,18.749 L8.5,13 C8.5,12.4871642 8.11395981,12.0644928 7.61662113,12.0067277 L7.5,12 Z M13.5,9 C12.9871642,9 12.5644928,9.38604019 12.5067277,9.88337887 L12.5,10 L12.5,18.749 L14.5,18.749 L14.5,10 C14.5,9.48716416 14.1139598,9.06449284 13.6166211,9.00672773 L13.5,9 Z M19.5,6 C18.9871642,6 18.5644928,6.38604019 18.5067277,6.88337887 L18.5,7 L18.5,18.749 L20.5,18.749 L20.5,7 C20.5,6.48716416 20.1139598,6.06449284 19.6166211,6.00672773 L19.5,6 Z"
        id="图表"
        fill="#444444"
      />
    </Svg>
  );
};

export default Icon;
